import React, { createContext, useReducer, Dispatch } from 'react';
import {
  csvReducer,
  ICsvContextActions,
  ICsvContextState,
  initialCsvState,
} from './csv-reducer';

interface ContextProps {
  state: ICsvContextState;
  dispatch: Dispatch<ICsvContextActions>;
}

export const CSVparserContext = createContext({} as ContextProps);

interface ICSVparserStoreProvider {
  children: React.ReactNode;
}

export const CsvStoreProvider = ({ children }: ICSVparserStoreProvider) => {
  const [state, dispatch] = useReducer(csvReducer, initialCsvState);
  const value = { state, dispatch };
  return (
    <CSVparserContext.Provider value={value}>
      {children}
    </CSVparserContext.Provider>
  );
};
