// TODO replace this with the complete one courses come from the course records
export interface IEmployerCourse {
  id?: string;
  name: string;
  learningAimId?: number;
  learningAimTitle?: string;
}

export interface IEmployerAddress {
  building: string;
  street: string;
  city: string;
  county: string;
  postcode: string;
  phone: string;
}

export interface IEmployerContact {
  name: string;
  jobTitle: string;
  email: string;
}

export interface IEmployerSectorLearner {
  id?: string;
  dateAdded?: string;
  name: string;
}

export interface IEmployerSector {
  id?: string;
  name: string;
  contact: IEmployerContact;
  course: IEmployerCourse;
  learners: IEmployerSectorLearner[];
}

export interface IEmployer {
  id?: string;
  lastModified?: string;
  isCompleted?: boolean;
  name: string;
  edsNumber: string;
  levy: boolean;
  onefileOrganisationId: number;
  onefilePlacementId: number;
  address: IEmployerAddress;
  contact: IEmployerContact;
  sectors: IEmployerSector[];
}

export interface IEmployerInListSector {
  id: string;
  assignedLearners: number;
  learners: {
    learnerId: string;
    learnerName: string;
  }[];
  name: string;
}

export interface IEmployerInList {
  employerName: string;
  lastModified: string;
  id: string;
  sectors: IEmployerInListSector[];
}

export interface IEmployerContextState {
  currentEmployer: IEmployer | null;
  employers: IEmployerInList[];
}

export interface ISetEmployerAction {
  type: 'SET_EMPLOYER';
  payload: IEmployer | null;
}

export interface ISetEmployersAction {
  type: 'SET_EMPLOYERS';
  payload: IEmployerInList[];
}

export interface IAddEmployerAction {
  type: 'ADD_EMPLOYER';
  payload: IEmployerInList;
}

export type IEmployerContextActions =
  | ISetEmployerAction
  | ISetEmployersAction
  | IAddEmployerAction;

export const initialEmployerState: IEmployerContextState = {
  currentEmployer: null,
  employers: [],
};

export const employerReducer = (
  state: IEmployerContextState,
  action: IEmployerContextActions,
) => {
  switch (action.type) {
    case 'SET_EMPLOYER':
      return {
        ...state,
        currentEmployer: action.payload,
      };
    case 'SET_EMPLOYERS':
      return {
        ...state,
        employers: action.payload,
      };
    case 'ADD_EMPLOYER':
      return {
        ...state,
        employers: [...state.employers, action.payload],
      };
    default:
      return state;
  }
};
