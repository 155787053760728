import React, { createContext, Dispatch, useReducer } from 'react';
import {
  initialUIState,
  IUIActions,
  IUIContextState,
  uiReducer,
} from './ui-reducer';

interface IContextProps {
  state: IUIContextState;
  dispatch: Dispatch<IUIActions>;
}

interface IUIStoreProvider {
  children: React.ReactNode;
}

export const UIContext = createContext({} as IContextProps);

export const UIStoreProvider = ({ children }: IUIStoreProvider) => {
  const [state, dispatch] = useReducer(uiReducer, initialUIState);
  const value = { state, dispatch };
  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
