exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-build-form-index-tsx": () => import("./../../../src/pages/build-form/index.tsx" /* webpackChunkName: "component---src-pages-build-form-index-tsx" */),
  "component---src-pages-build-workflow-index-tsx": () => import("./../../../src/pages/build-workflow/index.tsx" /* webpackChunkName: "component---src-pages-build-workflow-index-tsx" */),
  "component---src-pages-bulk-import-learners-error-correction-index-tsx": () => import("./../../../src/pages/bulk-import-learners/error-correction/index.tsx" /* webpackChunkName: "component---src-pages-bulk-import-learners-error-correction-index-tsx" */),
  "component---src-pages-bulk-import-learners-index-tsx": () => import("./../../../src/pages/bulk-import-learners/index.tsx" /* webpackChunkName: "component---src-pages-bulk-import-learners-index-tsx" */),
  "component---src-pages-bulk-import-learners-select-headers-index-tsx": () => import("./../../../src/pages/bulk-import-learners/select-headers/index.tsx" /* webpackChunkName: "component---src-pages-bulk-import-learners-select-headers-index-tsx" */),
  "component---src-pages-choose-form-type-index-tsx": () => import("./../../../src/pages/choose-form-type/index.tsx" /* webpackChunkName: "component---src-pages-choose-form-type-index-tsx" */),
  "component---src-pages-complete-form-index-tsx": () => import("./../../../src/pages/complete-form/index.tsx" /* webpackChunkName: "component---src-pages-complete-form-index-tsx" */),
  "component---src-pages-customer-form-index-tsx": () => import("./../../../src/pages/customer-form/index.tsx" /* webpackChunkName: "component---src-pages-customer-form-index-tsx" */),
  "component---src-pages-employer-form-index-tsx": () => import("./../../../src/pages/employer-form/index.tsx" /* webpackChunkName: "component---src-pages-employer-form-index-tsx" */),
  "component---src-pages-employer-index-tsx": () => import("./../../../src/pages/employer/index.tsx" /* webpackChunkName: "component---src-pages-employer-index-tsx" */),
  "component---src-pages-enquiry-form-index-tsx": () => import("./../../../src/pages/enquiry-form/index.tsx" /* webpackChunkName: "component---src-pages-enquiry-form-index-tsx" */),
  "component---src-pages-eula-terms-index-tsx": () => import("./../../../src/pages/eula-terms/index.tsx" /* webpackChunkName: "component---src-pages-eula-terms-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learner-form-index-tsx": () => import("./../../../src/pages/learner-form/index.tsx" /* webpackChunkName: "component---src-pages-learner-form-index-tsx" */),
  "component---src-pages-learner-index-tsx": () => import("./../../../src/pages/learner/index.tsx" /* webpackChunkName: "component---src-pages-learner-index-tsx" */),
  "component---src-pages-learner-review-completed-form-index-tsx": () => import("./../../../src/pages/learner/review-completed-form/index.tsx" /* webpackChunkName: "component---src-pages-learner-review-completed-form-index-tsx" */),
  "component---src-pages-list-courses-index-tsx": () => import("./../../../src/pages/list-courses/index.tsx" /* webpackChunkName: "component---src-pages-list-courses-index-tsx" */),
  "component---src-pages-list-employers-index-tsx": () => import("./../../../src/pages/list-employers/index.tsx" /* webpackChunkName: "component---src-pages-list-employers-index-tsx" */),
  "component---src-pages-list-enquiries-index-tsx": () => import("./../../../src/pages/list-enquiries/index.tsx" /* webpackChunkName: "component---src-pages-list-enquiries-index-tsx" */),
  "component---src-pages-list-forms-index-tsx": () => import("./../../../src/pages/list-forms/index.tsx" /* webpackChunkName: "component---src-pages-list-forms-index-tsx" */),
  "component---src-pages-list-learners-index-tsx": () => import("./../../../src/pages/list-learners/index.tsx" /* webpackChunkName: "component---src-pages-list-learners-index-tsx" */),
  "component---src-pages-list-users-index-tsx": () => import("./../../../src/pages/list-users/index.tsx" /* webpackChunkName: "component---src-pages-list-users-index-tsx" */),
  "component---src-pages-no-auth-tsx": () => import("./../../../src/pages/no-auth.tsx" /* webpackChunkName: "component---src-pages-no-auth-tsx" */),
  "component---src-pages-review-completed-form-index-tsx": () => import("./../../../src/pages/review-completed-form/index.tsx" /* webpackChunkName: "component---src-pages-review-completed-form-index-tsx" */),
  "component---src-pages-review-employer-form-index-tsx": () => import("./../../../src/pages/review-employer-form/index.tsx" /* webpackChunkName: "component---src-pages-review-employer-form-index-tsx" */),
  "component---src-pages-review-employer-reject-form-index-tsx": () => import("./../../../src/pages/review-employer-reject-form/index.tsx" /* webpackChunkName: "component---src-pages-review-employer-reject-form-index-tsx" */),
  "component---src-pages-review-forms-index-tsx": () => import("./../../../src/pages/review-forms/index.tsx" /* webpackChunkName: "component---src-pages-review-forms-index-tsx" */),
  "component---src-pages-review-learner-form-index-tsx": () => import("./../../../src/pages/review-learner-form/index.tsx" /* webpackChunkName: "component---src-pages-review-learner-form-index-tsx" */),
  "component---src-pages-review-learner-reject-form-index-tsx": () => import("./../../../src/pages/review-learner-reject-form/index.tsx" /* webpackChunkName: "component---src-pages-review-learner-reject-form-index-tsx" */),
  "component---src-pages-send-employer-form-index-tsx": () => import("./../../../src/pages/send-employer-form/index.tsx" /* webpackChunkName: "component---src-pages-send-employer-form-index-tsx" */),
  "component---src-pages-send-employer-reject-form-index-tsx": () => import("./../../../src/pages/send-employer-reject-form/index.tsx" /* webpackChunkName: "component---src-pages-send-employer-reject-form-index-tsx" */),
  "component---src-pages-send-learner-form-index-tsx": () => import("./../../../src/pages/send-learner-form/index.tsx" /* webpackChunkName: "component---src-pages-send-learner-form-index-tsx" */),
  "component---src-pages-send-learner-reject-form-index-tsx": () => import("./../../../src/pages/send-learner-reject-form/index.tsx" /* webpackChunkName: "component---src-pages-send-learner-reject-form-index-tsx" */),
  "component---src-pages-view-draft-form-index-tsx": () => import("./../../../src/pages/view-draft-form/index.tsx" /* webpackChunkName: "component---src-pages-view-draft-form-index-tsx" */),
  "component---src-pages-view-enquiry-index-tsx": () => import("./../../../src/pages/view-enquiry/index.tsx" /* webpackChunkName: "component---src-pages-view-enquiry-index-tsx" */),
  "component---src-pages-view-form-index-tsx": () => import("./../../../src/pages/view-form/index.tsx" /* webpackChunkName: "component---src-pages-view-form-index-tsx" */)
}

