import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { getQueryVar } from '../../utils/query-vars';
import {
  employerReducer,
  IEmployerContextActions,
  IEmployerContextState,
  initialEmployerState,
} from './employer-reducer';

interface ContextProps {
  state: IEmployerContextState;
  dispatch: Dispatch<IEmployerContextActions>;
}

export const EmployerContext = createContext({} as ContextProps);

interface IEmployerStoreProvider {
  children: React.ReactNode;
}

export const EmployerStoreProvider = ({ children }: IEmployerStoreProvider) => {
  const [state, dispatch] = useReducer(employerReducer, initialEmployerState);
  const value = { state, dispatch };
  return (
    <EmployerContext.Provider value={value}>
      {children}
    </EmployerContext.Provider>
  );
};

export const useCurrentEmployer = () => {
  return useContext(EmployerContext).state.currentEmployer;
};

export const useCurrentSectorIndex = () => {
  const employer = useCurrentEmployer();
  const urlIndex = parseInt(getQueryVar('tab') || '', 10);
  const index = employer?.sectors[urlIndex] ? urlIndex : 0;
  return index;
};

export const useCurrentSector = () => {
  const employer = useCurrentEmployer();
  const urlIndex = parseInt(getQueryVar('tab') || '', 10);
  const index = employer?.sectors[urlIndex] ? urlIndex : 0;
  return employer?.sectors[index];
};
