import React, { createContext, Dispatch, useReducer } from 'react';
import {
  IUserState,
  UserActions,
  userInitialState,
  userReducer,
} from './user-reducer';

interface UserContextProps {
  state: IUserState;
  dispatch: Dispatch<UserActions>;
}

interface IUserStoreProvider {
  children: React.ReactNode;
}

export const UserContext = createContext({} as UserContextProps);

export const UserContextProvider = ({ children }: IUserStoreProvider) => {
  const [state, dispatch] = useReducer(userReducer, userInitialState);
  const value = { state, dispatch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
