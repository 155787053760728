import { ILearnerInList } from '../learner/learner-reducer';
import { TFormTemplateStatus } from './form-types';
import { StageId } from './form-workflows';
import {
  IFormField,
  IFormFieldPrePop,
  IFormImage,
  IFormTable,
  IFormText,
} from './forms-fields';

export type IFormDataItem =
  | IFormField
  | IFormText
  | IFormImage
  | IFormTable
  | IFormFieldPrePop;

export interface IWorkflow {
  id?: number;
  workflowName: string;
  custom: boolean;
  isActive: boolean;
  stages: IWorkflowStage[];
}

export interface IWorkflowStage {
  id?: number;
  stageId: StageId;
  emailTemplate?: IEmailTemplate;
}

export interface IWorkflowStageName {
  id?: number;
  workflowName: string;
  stageId: StageId;
  stageName?: string;
}

export interface IWorkflowName {
  id?: number;
  name: string;
  custom: boolean;
}

export interface IEmailTemplate {
  subject: string;
  message: string;
  signoff: string;
  remindEvery?: number;
  expireAfterValue?: number;
  expireAfterUnit?: number;
}

export interface IFormTemplate {
  id?: string;
  formName: string;
  formData: IFormDataItem[];
  status: TFormTemplateStatus;
  isRejectForm?: boolean | undefined;
  isAmended?: boolean | undefined;
  isFormApproval?: boolean | undefined;
  workflowId: number;
  workflow: IWorkflow;
  nextStageId: StageId;
  currentStageId: StageId;
  isDuplicate: boolean;
  workflowStagesMismatch: boolean;
}

export interface IForm {
  author: string;
  databaseId: string;
  lastModified: string;
  formName: string;
  workflowId: number;
  workflowName: string;
  workflowTypeId: number;
  formStatus: string;
  needsEmployer: boolean;
  formAssigned: boolean;
}

export interface ISendFormToLearner {
  learner: ILearnerInList | null;
  template: IFormTemplate;
  formTemplateId?: string;
}

type TFormStatus = 0 | 1 | 2 | 3;

export interface IFormInReview {
  formName: string | null; // not sure why the API is returning this, don't use it use the name inside template
  courseName: string;
  id: string;
  dateSent: string;
  status: TFormStatus;
  employer: {
    sectorName: string;
    employerId: string;
    status: number;
    name: string;
    dateSent: string;
  };
  learner: {
    dateSent: string;
    learnerId: string;
    name: string;
    status: TFormStatus;
  };
  trainingProvider: {
    clientName: string;
    status: TFormStatus;
    trainingProviderId: number;
  };
  template: IFormTemplate;
}

export interface IFormsContextState {
  newForm: IFormTemplate;
  sendFormToLearner: ISendFormToLearner | null;
  sendFormToEmployer: IFormInReview | null;
  approvingForm: IFormTemplate | null;
  newWorkflow: IWorkflow;
}

interface IClearNewFormAction {
  type: 'CLEAR_NEW_FORM';
}

interface ISetNewFormAction {
  type: 'SET_NEW_FORM';
  payload: IFormTemplate;
}

interface ISetNewWorkflowAction {
  type: 'SET_NEW_WORKFLOW';
  payload: IWorkflow;
}

interface IClearNewWorkflowAction {
  type: 'CLEAR_NEW_WORKFLOW';
}

interface ISetSendFormToLearnerAction {
  type: 'SET_SEND_FORM_TO_LEARNER';
  payload: ISendFormToLearner;
}

interface ISendFormToEmployerAction {
  type: 'SET_SEND_FORM_TO_EMPLOYER';
  payload: IFormInReview;
}

interface IReviewingFormAction {
  type: 'SET_REVIEWING_FORM';
  payload: IFormTemplate;
}

interface IClearReviewingFormAction {
  type: 'CLEAR_REVIEWING_FORM';
}

export type IFormsContextActions =
  | IClearNewFormAction
  | ISetNewFormAction
  | ISetSendFormToLearnerAction
  | ISendFormToEmployerAction
  | IReviewingFormAction
  | IClearReviewingFormAction
  | ISetNewWorkflowAction
  | IClearNewWorkflowAction;

const emptyWorkflow: IWorkflow = {
  workflowName: '',
  stages: [],
  custom: true,
};

const emptyForm: IFormTemplate = {
  formName: '',
  workflowId: 1,
  formData: [],
  status: 'unsaved',
  workflow: emptyWorkflow,
};

export const initialState: IFormsContextState = {
  newForm: emptyForm,
  sendFormToLearner: null,
  sendFormToEmployer: null,
  approvingForm: null,
  newWorkflow: emptyWorkflow,
};

export const formReducer = (
  state: IFormsContextState,
  action: IFormsContextActions,
) => {
  switch (action.type) {
    case 'CLEAR_NEW_FORM':
      return {
        ...state,
        newForm: { ...emptyForm },
      };
    case 'CLEAR_NEW_WORKFLOW':
      return {
        ...state,
        newWorkflow: { ...emptyWorkflow },
      };
    case 'SET_NEW_FORM':
      return {
        ...state,
        newForm: action.payload,
      };
    case 'SET_NEW_WORKFLOW':
      return {
        ...state,
        newWorkflow: action.payload,
      };
    case 'SET_SEND_FORM_TO_LEARNER':
      return {
        ...state,
        sendFormToLearner: action.payload,
      };
    case 'SET_SEND_FORM_TO_EMPLOYER':
      return {
        ...state,
        sendFormToEmployer: action.payload,
      };
    case 'SET_REVIEWING_FORM':
      return {
        ...state,
        approvingForm: action.payload,
      };
    case 'CLEAR_REVIEWING_FORM':
      return {
        ...state,
        approvingForm: null,
      };
    default:
      return state;
  }
};
