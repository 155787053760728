import React from 'react';
import { UserContextProvider } from './src/context-providers/user/user-context';
import { EmployerStoreProvider } from './src/context-providers/employer/employer-context';
import { LearnerStoreProvider } from './src/context-providers/learner/learner-context';
import { UIStoreProvider } from './src/context-providers/ui/ui-context';
import { FormsStoreProvider } from './src/context-providers/forms/forms-context';
import { CsvStoreProvider } from './src/context-providers/csv/csv-context';

export const wrapRootElement = ({ element }) => {
  return (
    <UserContextProvider>
      <FormsStoreProvider>
        <EmployerStoreProvider>
          <CsvStoreProvider>
            <LearnerStoreProvider>
              <UIStoreProvider>{element}</UIStoreProvider>
            </LearnerStoreProvider>
          </CsvStoreProvider>
        </EmployerStoreProvider>
      </FormsStoreProvider>
    </UserContextProvider>
  );
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [];
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    window.scrollTo(0, 0);
  }

  return false;
};
