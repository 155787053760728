import { ImportHeader } from '../../components/organisms/import-learners-table/import-learner-constants';
import { ImportLearnerValidation } from '../../components/organisms/import-learners-table/import-learner-validation';

export interface ICsvContextState {
  data: string[][];
  headers: ImportHeader[];
  learners: ImportLearnerValidation[];
}

export interface ISetCsvHeaderAction {
  type: 'SET_HEADERS';
  headers: ImportHeader[];
}

export interface ISetImportLearnerValidationAction {
  type: 'SET_IMPORT_LEARNERS';
  learners: ImportLearnerValidation[];
}

export interface ISetCSVAction {
  type: 'SET_CSV';
  csvdata: string[][];
}

interface IClearCsvDataFormAction {
  type: 'CLEAR_CSV';
}

interface IClearAllDataFormAction {
  type: 'CLEAR_ALL';
}

interface IClearHeadersFormAction {
  type: 'CLEAR_HEADERS';
}

interface IClearLearnersFormAction {
  type: 'CLEAR_LEARNERS';
}

export type ICsvContextActions =
  | ISetCSVAction
  | IClearCsvDataFormAction
  | ISetCsvHeaderAction
  | ISetImportLearnerValidationAction
  | IClearAllDataFormAction
  | IClearLearnersFormAction
  | IClearHeadersFormAction;

export const initialCsvState: ICsvContextState = {
  data: [],
  headers: [],
  learners: [],
};

export const csvReducer = (
  state: ICsvContextState,
  action: ICsvContextActions,
) => {
  switch (action.type) {
    case 'CLEAR_ALL':
      return {
        ...state,
        data: [],
        headers: [],
        learners: [],
      };
    case 'CLEAR_LEARNERS':
      return {
        ...state,
        learners: [],
      };
    case 'CLEAR_HEADERS':
      return {
        ...state,
        headers: [],
      };
    case 'CLEAR_CSV':
      return {
        ...state,
        data: [],
      };
    case 'SET_CSV':
      return {
        ...state,
        data: action.csvdata,
      };
    case 'SET_HEADERS':
      return {
        ...state,
        headers: action.headers,
      };
    case 'SET_IMPORT_LEARNERS':
      return {
        ...state,
        learners: action.learners,
      };
    default:
      return state;
  }
};
