/**
 * Get query vars from URL and build new URLS with changed ones
 */

export const getQueryVar = (name: string) => {
  if (typeof window === 'object') {
    const queryString = new URLSearchParams(window.location.search);
    return queryString.get(name);
  }
  return undefined;
};

export const getAllQueryVar = (name: string) => {
  if (typeof window === 'object') {
    const queryString = new URLSearchParams(window.location.search);
    return queryString.getAll(name);
  }
  return undefined;
};

export const urlWithSetQueryVar = (name: string, value: string) => {
  if (typeof window === 'object') {
    const queryString = new URLSearchParams(window.location.search);
    queryString.set(name, value);
    return `${window.location.pathname}?${queryString.toString()}`;
  }
  return '';
};

export const urlWithSetMultipleQueryVar = (name: string, values: string[]) => {
  if (typeof window === 'object') {
    const queryString = new URLSearchParams(window.location.search);
    queryString.set(name, values.join(','));
    return `${window.location.pathname}?${queryString.toString()}`;
  }
  return '';
};

export const urlAppendMultipleQueryVar = (
  url: string,
  name: string,
  values: string[],
) => {
  if (typeof window === 'object') {
    const queryString = new URLSearchParams();
    queryString.set(name, values.join(','));
    return `${url}&${queryString.toString()}`;
  }
  return '';
};

export const deleteQueryParams = (...names: string[]) => {
  if (typeof window === 'object') {
    const queryString = new URLSearchParams(window.location.search);
    names.forEach((name) => {
      queryString.delete(name);
    });
    return `${window.location.pathname}?${queryString.toString()}`;
  }
  return '';
};

interface ISetQueryVar {
  name: string;
  value: string;
}

export const urlWithSetQueryVars = (values: ISetQueryVar[]) => {
  if (typeof window === 'object') {
    const queryString = new URLSearchParams(window.location.search);
    values.forEach((d) => {
      queryString.set(d.name, d.value);
    });
    return `${window.location.pathname}?${queryString.toString()}`;
  }
  return '';
};
