import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import {
  learnerReducer,
  ILearnerContextActions,
  ILearnerContextState,
  initialLearnerState,
} from './learner-reducer';

interface ContextProps {
  state: ILearnerContextState;
  dispatch: Dispatch<ILearnerContextActions>;
}

interface ILearnerStoreProvider {
  children: React.ReactNode;
}

export const LearnerContext = createContext({} as ContextProps);

export const LearnerStoreProvider = ({ children }: ILearnerStoreProvider) => {
  const [state, dispatch] = useReducer(learnerReducer, initialLearnerState);
  const value = { state, dispatch };
  return (
    <LearnerContext.Provider value={value}>{children}</LearnerContext.Provider>
  );
};

export const useCurrentLearner = () => {
  return useContext(LearnerContext).state.currentLearner;
};
