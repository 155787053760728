import Modal from 'react-modal';
import { getLocalStorage, setLocalStorage } from '../../utils/local-storage';
import { TModal } from '../../components/modals/ModalContent';

export interface IModal {
  component: null | TModal<any>;
  props?: any;
}

export interface IUIContextState {
  modal: IModal;
  menuOpen: boolean;
  dismissModal: boolean;
  style: Modal.Styles;
}

interface IOpenModalAction {
  type: 'OPEN_MODAL';
  payload: IModal;
}

interface ICloseModalAction {
  type: 'CLOSE_MODAL';
}

interface IOpenMenuAction {
  type: 'OPEN_MENU';
}

interface ICloseMenuAction {
  type: 'CLOSE_MENU';
}

interface IEditMenuAction {
  type: 'EDIT_TITLE_MODAL';
}

interface IResetEditMenuAction {
  type: 'RESET_EDIT_TITLE_MODAL';
}
interface IOnOverlayClickModal {
  type: 'DISMISS_MODAL';
}
interface IModalStyleChange {
  type: 'UPDATE_STYLE';
  payload: Modal.Styles;
}
interface IResetModalStyle {
  type: 'RESET_STYLE';
}

export type IUIActions =
  | IOpenModalAction
  | ICloseModalAction
  | IOpenMenuAction
  | ICloseMenuAction
  | IOnOverlayClickModal
  | IModalStyleChange
  | IEditMenuAction
  | IResetEditMenuAction
  | IResetModalStyle;

export const initialUIState: IUIContextState = {
  modal: {
    component: null,
    props: {},
  },
  menuOpen: !(getLocalStorage('menuOpen') === 'false'),
  dismissModal: false,
  style: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '8px',
      transition: 'all .3s ease-in',
      maxHeight: '95vh',
      padding: '32px',
    },
    overlay: {
      backgroundColor: 'rgba(44, 51, 63, .9)',
      zIndex: 100,
    },
  },
};

export const uiReducer = (state: IUIContextState, action: IUIActions) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        modal: { ...action.payload },
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modal: { component: null },
      };
    case 'OPEN_MENU':
      setLocalStorage('menuOpen', 'true');
      return {
        ...state,
        menuOpen: true,
      };
    case 'CLOSE_MENU':
      setLocalStorage('menuOpen', 'false');
      return {
        ...state,
        menuOpen: false,
      };
    case 'DISMISS_MODAL':
      return {
        ...state,
        dismissModal: !state.dismissModal,
      };
    case 'EDIT_TITLE_MODAL':
      return {
        ...state,
        dismissModal: !state.dismissModal,
      };
    case 'RESET_EDIT_TITLE_MODAL':
      return {
        ...state,
        dismissModal: initialUIState.dismissModal,
      };
    case 'UPDATE_STYLE':
      return {
        ...state,
        style: {
          ...initialUIState.style,
          content: {
            ...initialUIState.style.content,
            ...action.payload.content,
          },
          overlay: {
            ...initialUIState.style.overlay,
            ...action.payload.overlay,
          },
        },
      };
    case 'RESET_STYLE':
      return {
        ...state,
        style: initialUIState.style,
      };
    default:
      return state;
  }
};
