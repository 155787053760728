import React, { createContext, useReducer, Dispatch } from 'react';
import {
  IFormsContextActions,
  initialState,
  IFormsContextState,
  formReducer,
} from './forms-reducer';

interface ContextProps {
  state: IFormsContextState;
  dispatch: Dispatch<IFormsContextActions>;
}

export const FormsContext = createContext({} as ContextProps);

interface IFormsStoreProvider {
  children: React.ReactNode;
}

export const FormsStoreProvider = ({ children }: IFormsStoreProvider) => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const value = { state, dispatch };
  return (
    <FormsContext.Provider value={value}>{children}</FormsContext.Provider>
  );
};
