export const isClient = typeof window === 'object';

export function getLocalStorage(key: string) {
  return isClient ? window.localStorage.getItem(key) : '';
}

export const setLocalStorage = (key: string, value: string) => {
  if (isClient) window.localStorage.setItem(key, value);
};

export function getSessionStorage(key: string) {
  return isClient ? window.sessionStorage.getItem(key) : '';
}

export const setSessionStorage = (key: string, value: string) => {
  if (isClient) window.sessionStorage.setItem(key, value);
};
