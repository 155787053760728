import get from 'lodash/get';
import set from 'lodash/set';
import produce from 'immer';
import {
  IEmployerAddress,
  IEmployerContact,
  IEmployerCourse,
} from '../employer/employer-reducer';
import { ImportLearnerFeatureStatus } from '../enums/global-enums';
import { ILearnerCentre } from '../../utils/exportLearnerHelper';

export interface ILearnerContact {
  emailAddress: string;
  homePhoneNumber: string;
  mobilePhoneNumber: string;
}

export interface ILearnerAddress {
  building: string;
  street: string;
  city: string;
  county: string;
  postcode: string;
}

export interface ILearnerEmployer {
  name: string;
  sector: string;
  address?: IEmployerAddress;
  course?: IEmployerCourse;
  contact?: IEmployerContact;
}

export interface ILearner {
  admins: string;
  adminIds: string;
  completedStatus: number;
  enrolmentStatus: number | 1;
  id?: string;
  firstName: string;
  surname: string;
  lastModified?: string;
  createdOn?: string;
  nationalInsuranceNumber?: string;
  uln?: string;
  dateOfBirth: string;
  contact?: ILearnerContact;
  address?: ILearnerAddress;
  employer?: ILearnerEmployer;
  employerId?: string;
  sectorId?: string;
  isCompleted?: boolean;
  ePortfolioId?: number;
  ilrId?: number;
  lldd?: string;
  llddCode?: string;
  ethnicity?: string;
  ethnicityCode?: number;
  sex?: string;
  sexCode?: string;
  tutor?: string;
  tutorId?: number;
  centre: ILearnerCentre;
  centreId?: number;
  centreCount?: number;
  centreName?: string;
}

export interface ILearnerInList {
  id: string;
  emailAddress: string;
  courseName: string | null;
  employerName: string | null;
  employerId: string | null;
  learnerId: string;
  name: string;
  lastModified: string;
  completedStatus: number;
  enrolmentStatus: number | 0;
  dateOfBirth: string;
  uln: string;
}

export interface ILearnerContextState {
  currentLearner: ILearner | null;
  learners: ILearnerInList[];
}

export interface ISetLearnerAction {
  type: 'SET_LEARNER';
  payload: ILearner | null;
}

export interface ILearnerImport {
  importLearners: ImportLearnerFeatureStatus | null;
}

// object comes down from API incomplete
// this ensures that the structure is complete with all empty fields as strings
// allows us to have cleaner type checking in rest of codebase
const addMissingFieldsToLearner = (learner: ILearner) => {
  return produce(learner, (updated) => {
    [
      'uln',
      'nationalInsuranceNumber',
      'contact.homePhoneNumber',
      'contact.mobilePhoneNumber',
      'contact.emailAddress',
      'address.building',
      'address.street',
      'address.county',
      'address.city',
      'address.postcode',
    ].forEach((key) => {
      if (!get(updated, key)) {
        set(updated, key, '');
      }
    });
    return updated;
  });
};

export type ILearnerContextActions = ISetLearnerAction;

export const initialLearnerState: ILearnerContextState = {
  currentLearner: null,
  learners: [],
};

export interface ILearnerLLDD {
  Id: number;
  code: number;
  definition: string;
}

export interface ILearnerEthnicity {
  Id: number;
  code: number;
  definition: string;
}

export interface ILearnerSex {
  Id: number;
  code: number;
  definition: string;
}

export interface ILearnerTutor {
  id: number;
  definition: string;
}

export interface IEnrolmentStatus {
  id: number;
  description: string;
}

export enum CompleteStatus {
  Incomplete = 0,
  Completed = 3,
}

export interface ILearnerAdmin {
  id?: string;
  adminIdList: string;
}

export const learnerReducer = (
  state: ILearnerContextState,
  action: ILearnerContextActions,
) => {
  switch (action.type) {
    case 'SET_LEARNER':
      return {
        ...state,
        currentLearner:
          action.payload !== null
            ? addMissingFieldsToLearner(action.payload)
            : null,
      };
    default:
      return state;
  }
};
