import { Permission, Role } from '../enums/global-enums';

export interface IUser {
  firstName: string;
  lastName: string;
  role: Role;
  permissions: Permission[];
}

export interface IUserInList {
  id: number;
  name: string;
}

export interface IClient {
  name: string;
}

export interface IUserState {
  user: IUser | null;
}

interface ISetUserAction {
  type: 'SET_USER';
  payload: IUser | null;
}

export type UserActions = ISetUserAction;

export const userInitialState: IUserState = {
  user: null,
};

export const userReducer = (state: IUserState, action: UserActions) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
